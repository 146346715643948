import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useQuery } from "urql";
import Configuration from "../../../../Config/Config";
import { getCommonStorage } from "../../../../Config/gql";
import Common from "../../../../Utils/Common";
import { createOrderAction } from "../../../Actions/Order";
import { BasePage } from "../../ComponentCommon/BasePage";
import { QUERY_PRODUCT } from "../Product/maxcoach/ProductDetail/gql";
import { useDiscount } from "../Product/maxcoach/Products/useDiscount";
import { addItemToWishList, addItemToCart } from "../../../Services/CartService";
import { useHistory } from "react-router-dom";
import "./style.less";
import { Carousel } from "antd";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

interface IActiveVariants {
  id?: string;
  attributeValues?: {
    id: string;
  };
}

export default function DetailProductBasic(props: any) {
  const { t } = useTranslation();
  const id = props.match.params.id;
  const slider = useRef<any>();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [productImages, setProductImages] = useState<any[]>([]);
  const [productDetail, setProductDetail] = useState<any>({});
  const [productActive, setProductActive] = useState<{
    productId: string;
    productPrice?: any;
    activeImages?: any;
  }>({
    productId: "",
    productPrice: 0,
    activeImages: [],
  });
  const [activeVariantsId, setActiveVariantsId] = useState<IActiveVariants[]>([]);

  const [fetchProductResult] = useQuery({
    query: QUERY_PRODUCT,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        id,
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const [hasDiscounts, priceInfo] = useDiscount(productDetail);

  const _createOrder = (productId: string, quantity: number) => {
    const items = [
      {
        Id: Common.guid(),
        TargetId: productActive.productId,
        quantity: quantity,
      },
    ];
    const data = {
      Id: Common.guid(),
      MerchantId: localStorage.getItem(Configuration.merchantId),
      TargetId: localStorage.getItem(Configuration.merchantId),
      CustomerId: localStorage.getItem(Configuration.userId),
      Note: "",
      OrderItems: items,
      CreatedDate: Common.getCurrentDateTime(),
      CreatedBy: localStorage.getItem(Configuration.userId),
    };

    createOrderAction(data);
  };
  // get product detail data
  useEffect(() => {
    if (fetchProductResult.data?.product) {
      const product = fetchProductResult.data.product;
      setProductDetail({ ...product });
      const { attributeVariants }: any = product;
      attributeVariants.forEach((att: any) => {
        setActiveVariantsId((oldActiveVariants: IActiveVariants[]) => {
          return [
            ...oldActiveVariants,
            { id: att.id, attributeValues: { id: att.attributeValues[0].id } },
          ];
        });
      });
      setProductActive({
        productPrice: product.price,
        productId: product.id,
        activeImages: [...product.images],
      });
    }
  }, [fetchProductResult.data?.product]);

  // find active product

  useEffect(() => {
    if (!_.isEmpty(productDetail)) {
      const compareProductVariants: any[] = [];

      activeVariantsId.forEach((variant: IActiveVariants) => {
        compareProductVariants.push(variant.attributeValues?.id);
      });
      const imageList: any[] = [];
      productDetail.productVariants.forEach((product: any) => {
        if (product.images?.length > 0) {
          imageList.push(product.images);
        }
        // compare attributevaluesid vs productvariantid
        const tempIdProductArray = product.attributeValues.map((item: any) => item.id);

        if (
          JSON.stringify(compareProductVariants.sort()) ===
          JSON.stringify(tempIdProductArray.sort())
        ) {
          setProductActive({
            productPrice: product.price,
            productId: product.id,
            activeImages: [...product.images],
          });
        }
      });
      setProductImages([...imageList]);
    }
  }, [activeVariantsId]);
  // set active image
  useEffect(() => {
    if (productActive.activeImages && productActive.activeImages.length > 0) {
      const currentImageIndex = productImages.findIndex(
        (img) => img[0].id === productActive.activeImages[0]?.id
      );
      slider.current.goTo(currentImageIndex);
    }
  }, [productActive.activeImages]);
  const handleChangeVariant = (variant: any, att: any) => {
    setActiveVariantsId((oldActiveVariants: IActiveVariants[]) => {
      const tempData = oldActiveVariants.map((item: IActiveVariants) => {
        if (item.id === variant.id && att.id !== item.attributeValues?.id) {
          return { ...item, attributeValues: { id: att.id } };
        }
        return { ...item };
      });
      return [...tempData];
    });
  };
  return (
    <BasePage title={productDetail.name}>
      <div className="section section-padding-bottom-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              {/* <img
                  src={Common.getImageThumbnail(
                    productDetail.images,
                    "?mode=crop&width=570&height=570"
                  )}
                  alt="book"
                />
                {hasDiscounts && (
                  <div className="product-badges">
                    <span className="onsale">
                      {priceInfo.percent
                        ? `-${priceInfo.percent}%`
                        : `-${Common.formatMoney(priceInfo.cash, 0)}`}
                    </span>
                  </div>
                )} */}
              <div className="single-product-image">
                <Carousel
                  ref={(ref) => {
                    slider.current = ref;
                  }}>
                  {productImages?.map((images: any) => {
                    return (
                      <div>
                        <img
                          src={Common.getImageThumbnail(images, "?mode=crop&width=600&height=300")}
                          alt="book"
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-product-content">
                <h3 className="title">{productDetail.name}</h3>
                <span className="prices">
                  <span className="price-new">
                    {hasDiscounts
                      ? Common.formatMoney(priceInfo.price, 0)
                      : Common.formatMoney(productActive.productPrice, 0)}
                  </span>
                  {hasDiscounts ? (
                    <span className="price-old">
                      {Common.formatMoney(productActive.productPrice, 0)}
                    </span>
                  ) : null}
                </span>
                {/* <div className="product-ratings">
                  <span className="star-rating">
                    <span className="rating-active">ratings</span>
                  </span>
                  <a href="#reviews" className="review-link">
                    (<span className="count">2</span> customer reviews)
                  </a>
                </div> */}
                <div className="product-description">
                  <p>{productDetail.subDescription}</p>
                </div>
                <div className="quantity-button-wrapper">
                  <div className="quantity">
                    <label className="font-bold">Số lượng</label>
                    <input
                      value={quantity}
                      onChange={(e) => setQuantity(parseInt(e.target.value))}
                      type="number"
                      defaultValue={1}
                      min={1}
                    />
                  </div>
                  {/* <p className="stock in-stock">99 in stock</p> */}
                </div>
                <div className="flex flex-column mb-4">
                  <span className="font-bold text-uppercase"></span>
                  {productDetail.attributeVariants?.map((variant: any) => (
                    <div className="mb-3">
                      <div className="font-bold text-uppercase mb-2">{variant.name}</div>
                      <div>
                        {variant.attributeValues.map((att: any) => {
                          return (
                            <button
                              onClick={() => handleChangeVariant(variant, att)}
                              style={
                                activeVariantsId.find(
                                  (curVariant: IActiveVariants) =>
                                    curVariant.attributeValues?.id === att.id
                                ) && { backgroundColor: "#20AD96" }
                              }
                              className={`border-2 px-3 py-1 border-gray-200 border-solid mr-2`}>
                              {att.value || "N / A"}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="product-action">
                  <button
                    className={`btn btn-primary btn-hover-secondary ${
                      submitting ? "disabled" : ""
                    }`}
                    onClick={() => {
                      setSubmitting(true);
                      _createOrder(id, quantity);
                    }}>
                    Mua ngay
                  </button>
                  <button
                    onClick={() =>
                      addItemToCart({ productId: productActive.productId, dispatch, t })
                    }
                    className="btn btn-primary btn-hover-secondary">
                    Add to cart
                  </button>
                  <button
                    className="btn btn-wishlist btn-box"
                    onClick={() => addItemToWishList(id, history)}>
                    <i className="fal fa-heart"></i>
                  </button>
                  {/* <button className="btn btn-wishlist btn-box">
                    <i className="fal fa-heart" />
                  </button>
                  <button className="btn btn-compare btn-box">
                    <i className="fal fa-random" />
                  </button> */}
                </div>
                <div className="product-meta">
                  <div className="meta-item">
                    <h6>Sku:</h6>
                    <div className="meta-content">
                      <span className="sku">{productDetail.sku}</span>
                    </div>
                  </div>
                  <div className="meta-item">
                    <h6>Category:</h6>
                    <div className="meta-content">
                      {(productDetail.categories || []).map((category: any) => (
                        <a rel="tag">{category.name}</a>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="entry-product-share">
                  <h6>Share:</h6>
                  <div className="author-social-networks">
                    <div className="inner">
                      <a
                        className="hint--bounce hint--top hint--primary"
                        aria-label="Facebook"
                        href="#"
                        target="_blank">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a
                        className="hint--bounce hint--top hint--primary"
                        aria-label="Twitter"
                        href="#"
                        target="_blank">
                        <i className="fab fa-twitter" />
                      </a>
                      <a
                        className="hint--bounce hint--top hint--primary"
                        aria-label="Linkedin"
                        href="#"
                        target="_blank">
                        <i className="fab fa-linkedin" />
                      </a>
                      <a
                        className="hint--bounce hint--top hint--primary"
                        aria-label="Tumblr"
                        href="#"
                        target="_blank">
                        <i className="fab fa-tumblr-square" />
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="single-product-information section section-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="nav product-info-tab-list justify-content-center">
                <li>
                  <a className="active" data-toggle="tab" href="#tab-description">
                    Description
                  </a>
                </li>
              </ul>
              <div className="tab-content product-infor-tab-content">
                <div className="tab-pane fade show active" id="tab-description">
                  <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                      <div
                        className="description-list"
                        dangerouslySetInnerHTML={{ __html: productDetail.description }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
}
