import React, { useEffect } from "react";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import PropTypes from "prop-types";
import { FilterItems, RenderPagination, SideWidget } from "../../../Product/maxcoach/Products";
import { StyledBlogItem, StyledBlogItemBody, StyledRightSection } from "./styledComponents";
import * as esc from "../../../Product/maxcoach/Products/esc";
import { useQuery } from "urql";
import { queryAuthors, queryPrivilegeCategories, queryPermissionArticles } from "./gql";
import { getCommonStorage } from "../../../../../../Config/gql";
import { Observable, Observer } from "../../../../../../Utils/Ob";
import Common from "../../../../../../Utils/Common";
import Configuration from "../../../../../../Config/Config";
import moment from "moment";
import { useMount, useSetState } from "react-use";
import { Link } from "react-router-dom";
import { FetchingItemsEffect } from "../../../Course/maxcoach/UserCourse";
import { useTranslation } from "react-i18next";

const state = Observable({
  topics: [],
  authors: [],
});

const PublicBlog = Observer(function () {
  const {t} = useTranslation()
  const [fetchCategories] = useQuery({
    query: queryPrivilegeCategories,
    variables: (() => {
      const { merchantId, languageId, tokenId } = getCommonStorage();

      return { type: 2, merchantId, languageId, tokenUser: tokenId };
    })(),
    requestPolicy: "cache-and-network",
  });

  useMount(() => window.scrollTo(0, 0));

  const title = t("Blog");

  return (
    <React.Fragment>
      <TitleBreadcrumb title={title} />
      <div className="section section-padding-bottom">
        <div className={"container"}>
          <div className="row max-mb-n50">
            <LeftSection fetchCategories={fetchCategories} />
            <RightSection fetchCategories={fetchCategories} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

const LeftSection = Observer(function ({ fetchCategories }) {
  const {t} = useTranslation()
  const [fetchAuthors] = useQuery({
    query: queryAuthors,
    variables: (() => {
      const { merchantId, languageId } = getCommonStorage();

      return { merchantId, languageId };
    })(),
    requestPolicy: "cache-and-network",
  });

  const topics = fetchCategories.data?.privilegecategories?.items || [];
  const authors = fetchAuthors.data?.users?.items || [];

  return (
    <div className="col-lg-3 col-12 order-lg-1 max-mb-50">
      <SideWidget title={t("TOPICS")} canExpand={true} hideExpandButton={true}>
        <FilterItems
          items={topics}
          loading={fetchCategories.fetching}
          onChange={(v) => (state.topics = v)}
          search={false}
          value={state.topics}
          checkStrictly={false}
        />
      </SideWidget>
      <esc.SideWidgetDivider />
      <SideWidget title={t("AUTHORS")} canExpand={true} hideExpandButton={true}>
        <FilterItems
          items={authors}
          loading={fetchAuthors.fetching}
          onChange={(v) => (state.authors = v)}
          search={true}
          value={state.authors}
          placeholder={t("Enter author name")}
        />
      </SideWidget>
    </div>
  );
});

const RightSection = Observer(function ({ fetchCategories }) {
  const categories = fetchCategories.data?.privilegecategories?.items || [];
  const [pageState, setPageState] = useSetState({
    current: 0,
    limit: 6,
  });
  const [fetchPermissionArticles] = useQuery({
    query: queryPermissionArticles,
    variables: (() => {
      const { tokenId, merchantId, languageId } = getCommonStorage();

      return {
        tokenUser: tokenId,
        merchantId,
        languageId,
        categoryIds: state.topics.length === 0 ? categories.map((v) => v.id) : state.topics,
        authorIds: state.authors,
        limit: pageState.limit,
        offset: pageState.limit * pageState.current,
      };
    })(),
    requestPolicy: "cache-and-network",
    pause: fetchCategories.fetching,
  });

  useEffect(() => {
    setPageState({ current: 0 });
  }, [state.authors, state.topics]);

  const articles = fetchPermissionArticles.data?.articlesmember?.items || [];
  const total = fetchPermissionArticles.data?.articlesmember?.totalCount || 0;
  const isEmpty = total === 0;

  return (
    <StyledRightSection className="col-lg-9 col-12 order-lg-1 max-mb-50">
      <SideWidget title={<span id={"title"} />} canExpand={false}>
        <FetchingItemsEffect hidden={!fetchPermissionArticles.fetching} />
        <div
          className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30"
          hidden={fetchPermissionArticles.fetching}>
          {articles.map((article, i) => {
            return <RenderItem article={article} key={i} />;
          })}
        </div>
        <div className="row max-mt-50" hidden={isEmpty || fetchPermissionArticles.fetching}>
          <div className="col">
            <RenderPagination
              currentPage={pageState.current}
              totalItem={total}
              itemPerPage={pageState.limit}
              handlePageChange={(v) => {
                setPageState({ current: v });
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </div>
      </SideWidget>
    </StyledRightSection>
  );
});

export function RenderItem(props) {
  const { article, newTab } = props;
  const image = Common.getImageThumbnail(article.images, Configuration.imageFormatCoursesMaxcoach);
  const textCategory = article.categories.map((v) => v.name).join(", ") || "-";

  return (
    <StyledBlogItem className="col max-mb-30 animate__animated animate__fadeInUp">
      <StyledBlogItemBody>
        <div data-section={"image"}>
          <img src={image} alt={article.name} />
        </div>
        <div data-section={"info"}>
          <div data-section={"category"}>{textCategory}</div>
          <Link
            to={"/blog/" + article.id + "/" + Common.rewriteUrl(article.name)}
            data-section={"name"}
            target={newTab ? "_blank" : undefined}>
            {article.name}
          </Link>
          <div data-section={"date"}>{article.postTime != null ? moment(article.postTime).format("MMM DD, YYYY") : moment(article.createdDate).format("MMM DD, YYYY")}</div>
        </div>
      </StyledBlogItemBody>
    </StyledBlogItem>
  );
}

export function TitleBreadcrumb({ title }) {
  const {t} = useTranslation()
  return (
    <div className="page-title-section section">
      <div className="page-title">
        <div className="container">
          <h3 className="title">{t(title)}</h3>
        </div>
      </div>
      <Breadcrumb items={[{ name: "Home", href: "/" }, { name: title }]} />
    </div>
  );
}
TitleBreadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PublicBlog;
