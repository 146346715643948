import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useMeasure } from "react-use";
import size from "../../../../Utils/size";
import MCC from "../MiddleContent";

const Container = styled.div`
  clear: both;
  position: relative;
  margin-bottom: 80px;
`;
const Background = styled.div`
  position: absolute;
  top: 0;

  img {
    display: inline-block;
    min-height: 250px;
    object-fit: cover;
  }
`;
const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(0, 0, 0, 0) 100%
  );
`;
const TitleContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Gilroy !important;

  ${size.xs} {
    padding-right: 20px !important;
  }

  ${size.lg} {
    padding-left: 116px;
    padding-right: 116px;
  }
`;
const Title = styled.h1`
  color: #ffffff;
  display: block;
  font-size: 24px;
  margin-bottom: 16px;

  span {
    display: inline-block;
  }

  ${size.md} {
    font-size: 34px;
  }
  ${size.xl} {
    font-size: 54px;
  }
`;
const SubTitle = styled.p`
  color: #ffffff;
  display: block;
  font-size: 16px;
  margin-bottom: 45px !important;
  font-weight: 200;

  ${size.md} {
    font-size: 20px;
  }
  ${size.xl} {
    font-size: 24px;
  }

  span {
    max-width: 1200px;
    display: inline-block;
  }
`;
const Instroducing = styled.div`
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 18px;
  line-height: 18px;
  font-weight: bold;
`;
const LogoContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 40%;
  max-width: 550px;
  top: 0;
  right: 0;
  display: none;

  .dx-logo {
    width: 100px;
    padding: 10px 16px;
    background: #ffffff;
    border-radius: 8px;
    display: inline-block;
  }

  .dx-created-by {
    text-align: center;
    margin-bottom: 14px;
  }

  ${size.sm} {
    display: block;
  }

  ${size.md} {
    width: 30%;
  }

  ${size.xl} {
    width: 40%;
  }
`;
const SubInstroducing = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-top: -18px;
  line-height: 20px;
  margin-bottom: 45px;
  white-space: nowrap;
  span:not(:first-child) {
    margin-left: 30px;
  }
`;
HeaderScene.propTypes = {
  direction: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.any.isRequired,
  showIntroducing: PropTypes.bool,
  logo: PropTypes.string,
};

HeaderScene.defaultProps = {
  direction: "full",
  title: "Every skill you need in Life",
  subTitle: "You can start learning these courses and get certified within a day.",
  image: require("../../../Assets/svg/banner-city.png").default,
  showIntroducing: false,
};

function HeaderScene(props) {
  const [imageRef, imageInfo] = useMeasure();
  const [rightLogoRef, rightLogoInfo] = useMeasure();

  const cssDirection = { [props.direction]: 0 };
  const bannerWidth = props.direction === "full" ? "100%" : "auto";

  if (["center", "full"].includes(props.direction)) {
    cssDirection.width = "100%";
    cssDirection.textAlign = "center";
  }

  const paddingRight = rightLogoInfo.width || 0;

  return (
    <Container style={{ height: imageInfo.height, pointerEvents: "none" }}>
      <Background style={cssDirection}>
        <img
          ref={imageRef}
          src={props.image? props.image : require("../../../Assets/svg/banner-city.png").default}
          alt={"scene"}
          style={{ width: bannerWidth, minHeight: props.minHeight == undefined ? 475 : props.minHeight, maxHeight: props.maxHeight == undefined ? 475 : props.maxHeight }}
        />
      </Background>
      <Overlay />
      {(props.title || props.subtitle) && (
        <TitleContainer
          style={{
            textAlign: props.showIntroducing || props.left ? "left" : "center",
            paddingRight: props.logo ? paddingRight : undefined,
          }}>
          {props.showIntroducing && <Instroducing>INTRODUCING</Instroducing>}
          {props.title && (
            <Title>
              <span>{props.title}</span>
            </Title>
          )}
          {props.subTitle && (
            <SubTitle>
              <span
                dangerouslySetInnerHTML={{
                  __html: (() => {
                    let html = props.subTitle || "";
                    html = html.replace(/font-family:(.*?);/gi, "");

                    return html;
                  })(),
                }}
              />
            </SubTitle>
          )}
          {props.showIntroducing && (
            <SubInstroducing>
              <span>
                <b>50+</b> courses
              </span>
              <span>
                <b>50+</b> instructors
              </span>
              <span>
                <b>9999+</b> students
              </span>
            </SubInstroducing>
          )}
        </TitleContainer>
      )}
      <LogoContainer hidden={!props.logo} ref={rightLogoRef}>
        <MCC>
          <Instroducing className="dx-created-by">CREATED BY</Instroducing>
          <span className={"dx-logo"}>
            <img src={props.logo} alt={"Logo"} />
          </span>
        </MCC>
      </LogoContainer>
    </Container>
  );
}



export default HeaderScene;
