import React, { useEffect, useRef, useState } from "react";
import { useDebounce, useLocation, useMeasure, useSetState } from "react-use";
import Configuration from "../../../../../../Config/Config";
import { getCommonStorage } from "../../../../../../Config/gql";
import { Carousel, Empty, Pagination, Skeleton, Spin, Tooltip } from "antd";
import MCC from "../../../../ComponentCommon/MiddleContent";
import Common from "../../../../../../Utils/Common";
import { Link } from "react-router-dom";
import { SpinIcon } from "../../../../ComponentCommon/PageLoading";
import { useHistory } from "react-router-dom";
import { useQuery } from "urql";
import HeaderScene from "../../../../ComponentCommon/HeaderScene";
import "./styles.less";
import PropTypes from "prop-types";
import _ from "lodash";
import { Tree } from "antd";
import { Input } from "antd";
import * as esc from "./esc";
import moment from "moment";
import { QUERY_PRODUCT_CATEGORIES, QUERY_SKIP_PURCHASE_PRODUCTS } from "./gql";
import { queryAuthors } from "../../../Blog/maxcoach/PublicBlog/gql";
import { useSelector } from "react-redux";
import {
  CourseProgressBar,
  CourseProgressBarValue,
  SideSortSection,
  SideWidgetDescription,
} from "../../../Course/maxcoach/UserCourse/styled";
import { Select } from "antd";
import { ClearAll, CustomInput, NoItems } from "./styled";
import queryString from "query-string";
import { useDiscount } from "./useDiscount";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function MaxcoachProductsPage() {
  const {t} = useTranslation()
  const history = useHistory();
  const merchant = useSelector((state) => state.Merchant.merchant);
  const location = useLocation();
  const [state, setState] = useSetState({
    itemPerPage: 24,
    totalItem: 0,
    currentPage: 0,
    categoryIds: [],
    authorIds: [],
    courseTypeIds: [],
    newestProducts: [],
    sortBy: "createdDate",
    filterByCourseTypes: [],
    filterByProviders: [],
    filterByIntructors: [],
    filterByPopular: [],
    minStudents: 0,
    maxStudents: 10000000,
    keyword: "",
  });
  const [fetchCategoriesResult] = useQuery({
    query: QUERY_PRODUCT_CATEGORIES,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        type: 3,
        merchantId: commonStorage.merchantId,
        languageId: Common.getCurrentLanguageId(),
      };
    })(),
  });

  const [fetchAuthors] = useQuery({
    query: queryAuthors,
    variables: (() => {
      const { merchantId, languageId } = getCommonStorage();

      return { merchantId, languageId };
    })(),
    requestPolicy: "cache-and-network",
  });

  const [fetchProductsResult] = useQuery({
    query: QUERY_SKIP_PURCHASE_PRODUCTS,
    variables: (() => {
      const commonStorage = getCommonStorage();
      let order = "desc";
      let sort = state.sortBy;

      if (sort.includes("|")) {
        const temp = sort.split("|");
        order = temp[0];
        sort = temp[1];
      }

      return {
        type: Configuration.productType.course,
        merchantId: commonStorage.merchantId,
        languageId: Common.getCurrentLanguageId(),
        offset: state.itemPerPage * state.currentPage,
        limit: state.itemPerPage,
        categoryIds: state.categoryIds.concat(state.filterByCourseTypes),        
        authorIds: state.authorIds.concat(state.filterByCourseTypes),
        sort,
        order,
        minStudents: state.minStudents,
        maxStudents: state.maxStudents,
        keyword: state.keyword,
        tokenUser: commonStorage.tokenId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const scrollToList = () => {
    const allCoursesElement = document.getElementById("all-courses");

    if (allCoursesElement) {
      allCoursesElement.scrollIntoView();
    }
  };
  const handlePageChange = (pageIndex, shouldScroll = true) => {
    setState({ currentPage: pageIndex });

    if (shouldScroll) {
      scrollToList();
    }
  };
  const _clearAll = () => {
    setState({
      categoryIds: [],
      filterByCourseTypes: [],
      filterByProviders: [],
      filterByIntructors: [],
      filterByPopular: [],
      keyword: "",
    });
    history.push({
      pathname: "/product",
      search: "",
    });
  };

  useEffect(() => {
    const params = queryString.parse(location.search);

    window.scrollTo(0, 0);
    setState({ keyword: params.q || "" });
  }, [location.search]);
  useEffect(() => {
    window.scrollTo(0, 0);
    handlePageChange(0, false);
  }, [location.pathname]);
  useEffect(() => {
    if (fetchProductsResult.data) {
      console.log(fetchProductsResult.data,"run 141")
      const products = fetchProductsResult.data?.skippurchasedproducts?.items || [];

      setState({
        totalItem: fetchProductsResult.data?.skippurchasedproducts?.totalCount || 0,
      });

      /* if (state.newestProducts.length === 0) { */
        const newestProducts = [];

        for (let i = 0; i < Math.min(products.length, 3); i++) {
          newestProducts.push(products[i]);
        }

        setState({ newestProducts });
      /* } */
    }
  }, [fetchProductsResult.data?.skippurchasedproducts?.items]);
 
  useEffect(() => {
    if (state.filterByPopular.length) {
      let min = Number.MAX_VALUE;
      let max = Number.MIN_VALUE;
      _.forEach(state.filterByPopular, (v) => {
        const arr = v.split("-").map((v) => +v);

        for (let n of arr) {
          if (n < min) min = n;
          if (n > max) max = n;
        }
      });

      setState({ minStudents: min, maxStudents: max });
    } else {
      setState({ minStudents: 0, maxStudents: 10000000 });
    }
  }, [state.filterByPopular]);

  const isSuccess = !!fetchProductsResult.data;
  const products = fetchProductsResult.data?.skippurchasedproducts?.items || [];  
  const categories = fetchCategoriesResult.data?.categories?.items || [];
  // const isHome = location.pathname === "/";
  const isHome = true;
  const izziMember = JSON.parse(merchant.izziMember || "{}");
  const showClearAll =
    state.categoryIds.length > 0 ||
    state.filterByIntructors.length > 0 ||
    state.filterByProviders.length > 0 ||
    state.filterByCourseTypes.length > 0 ||
    state.filterByPopular.length > 0;
  const rootCategories = categories.filter((v) => {
    return !v.parentId || v.parentId === "00000000-0000-0000-0000-000000000000";
  });
  const childCategories = (() => {
    const temp = _.cloneDeep(state.categoryIds);
    const ignores = {};

    if (state.categoryIds.length > 0) {
      while (true) {
        let count = 0;

        for (let j = 0; j < categories.length; j++) {
          const v = categories[j];

          if (ignores[v.id]) continue;

          if (temp.includes(v.parentId)) {
            temp.push(v.id);
            ignores[v.id] = true;
            count++;
          }
        }

        if (count === 0) {
          break;
        }
      }
    }

    return categories.filter((v) => {
      if (state.categoryIds.length > 0) {
        return temp.includes(v.parentId);
      }

      return v.parentId && v.parentId !== "00000000-0000-0000-0000-000000000000";
    });
  })();
  const authors = fetchAuthors.data?.users?.items || [];
  const isEmpty = isSuccess && products.length === 0;
  const popularFilterList = [
    // { id: "1-1000", name: "< 1000 students" },
    // { id: "2-4", name: "< 1000 students" },
    { id: "1000-2000", name: "1000 - 2000 students" },
    { id: "2000-4000", name: "2000 - 4000 students" },
    { id: "5000-100000000", name: "> 5000 students" },
  ];
  const merchantFilterList = [merchant];

  if (isEmpty) {
    window.scrollTo(0, 0);
  }

  return (
    <React.Fragment>
      <div className={"dn"} id={"dongsec"}>
        <div className={"wraper"}>
          <HeaderScene
            image={isHome ? izziMember.ImagePath : undefined}
            title={isHome ? " " : undefined}
            subTitle={isHome ? izziMember.Description || " " : undefined}
            showIntroducing={!isHome}
            logo={
              !isHome && Common.getImageThumbnail(merchant.images, "?mode=crop&width=100&height=64")
            }
          />
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row max-mb-n50">
                <div className="col-lg-3 col-12 order-lg-1 max-mb-50">
                  <React.Fragment>
                    <SideWidget title={t("CATEGORIES")} canExpand={true}>
                      <FilterItems
                        items={rootCategories}
                        loading={fetchCategoriesResult.fetching}
                        onChange={(categoryIds) => setState({ categoryIds })}
                        search={true}
                        value={state.categoryIds}
                        placeholder={t("Enter category name")}
                      />
                    </SideWidget>
                    <esc.SideWidgetDivider />
                    <SideWidget title={t("COURSE TYPE")} canExpand={true}>
                      <FilterItems
                        items={childCategories}
                        onChange={(filterByCourseTypes) => setState({ filterByCourseTypes })}
                        value={state.filterByCourseTypes}
                        checkStrictly={false}
                      />
                    </SideWidget>
                    <esc.SideWidgetDivider />
                    {/* <SideWidget title={t("PROVIDER")} canExpand={true}>
                      <FilterItems
                        items={merchantFilterList}
                        onChange={(filterByProviders) => setState({ filterByProviders })}
                        value={state.filterByProviders}
                        search={true}
                        placeholder={t("Find a provider")}
                      />
                    </SideWidget> 
                    <esc.SideWidgetDivider />*/}
                    <SideWidget title={t("INSTRUCTOR")} canExpand={true}>
                      <FilterItems
                        items={authors}
                        loading={fetchAuthors.fetching}
                        onChange={(authorIds) => setState({ authorIds })}
                        value={state.authorIds}
                        search={true}
                        placeholder={t("Enter instructor name")}
                      />
                    </SideWidget>
                    {/*<esc.SideWidgetDivider />
                     <SideWidget title={t("POPULAR")} canExpand={true}>
                      <FilterItems
                        items={popularFilterList}
                        onChange={(filterByPopular) => setState({ filterByPopular })}
                        value={state.filterByPopular}
                        search={false}
                        placeholder={t("Enter instructor name")}
                      />
                    </SideWidget> */}
                    {showClearAll && <ClearAll onClick={_clearAll}>{t("Clear All")}</ClearAll>}
                  </React.Fragment>
                </div>
                <div className="col-lg-9 col-12 order-lg-1 max-mb-50">
                  <React.Fragment>
                    <div hidden={state.newestProducts.length === 0 || isEmpty}>
                      <CardCarousel
                        title={t("Featured")}
                        items={state.newestProducts}
                        renderItem={(item) => {
                          const src = Common.getImageThumbnail(
                            item.images,
                            Configuration.imageFormatCoursesMaxcoach
                          );
                          const courseUrl = `/product/${item.id}/${Common.rewriteUrl(item.name)}`;

                          return (
                            <esc.HorizontalProductItem onClick={() => history.push(courseUrl)}>
                              <div className={"dx-cell"}>
                                <div className={"dx-content"}>
                                  <h1 className={"dx-title"}>{item.name}</h1>
                                  <p className={"dx-desc"}>
                                    {item.subDescription && (
                                      <span>{Common.formatName(item.subDescription, 160)}</span>
                                    )}
                                    {!item.subDescription && (
                                      <span>
                                        {(() => {
                                          const metaTag = JSON.parse(merchant.metaTag || "{}");

                                          return Common.formatName(metaTag.Description, 160);
                                        })()}
                                      </span>
                                    )}
                                  </p>
                                  <div className={"dx-author"}>
                                    <div className={"dx-avatar"}>
                                      <img
                                        src={getAuthorAvatar(item, merchant, "")}
                                        alt={"Avatar"}
                                      />
                                    </div>
                                    <div className={"dx-name"}>
                                      <p>{getAuthorNames(item, merchant)}</p>
                                      <p>
                                        {(() => {
                                          const metaTag = JSON.parse(merchant.metaTag || "{}");

                                          return metaTag.Title;
                                        })()}
                                      </p>
                                    </div>
                                  </div>
                                  <esc.PreviewCourseInfo className={"dx-rating"}>
                                    {!(item.quantityRating > 0) && <p>{t("No review yet")}</p>}
                                    {item.quantityRating > 0 && (
                                      <p>
                                        <i className="fas fa-star" />{" "}
                                        <span>{parseFloat(item.averageRating).toFixed(1)}</span> (
                                        {item.quantityRating})
                                      </p>
                                    )}
                                    <p>
                                      <span>
                                        {Number(item.quantityStudent).toLocaleString("vi-VN")}
                                      </span>
                                      {" students"}
                                    </p>
                                  </esc.PreviewCourseInfo>
                                  <div className={"dx-price2"}>
                                    {Number(item.price).toLocaleString("vi-VN")}₫
                                  </div>
                                </div>
                              </div>
                              <div className={"dx-cell"}>
                                <img src={src} alt="Thumbnail" />
                                <esc.CarouselImageInfo>
                                  <div>
                                    <span className={"dx-brand"} hidden={!item.merchantLogo}>
                                      <img
                                        src={Configuration.imageUrl + item.merchantLogo}
                                        alt={"Brand"}
                                      />
                                    </span>
                                  </div>
                                  <div>
                                    <span className={"dx-hours"}>
                                      {formatTimeToLearn(item.periodCourse * 60)}
                                    </span>
                                  </div>
                                </esc.CarouselImageInfo>
                              </div>
                              {/*{index === 0 && <div className={"dx-rank dx-rank-blue"}>Popular</div>}*/}
                              <div className={"dx-rank dx-rank-pink"}>{t("New")}</div>
                            </esc.HorizontalProductItem>
                          );
                        }}
                      />
                    </div>
                    {isEmpty && (
                      <NoItems>
                        <h3>{t("No results")}</h3>
                        <p>{t("Try adjusting your search by removing filters")}.</p>
                        <button className={"btn btn-primary"} onClick={_clearAll}>
                          {t("Remove all filters")}
                        </button>
                        <hr />
                        <h3>{t("Some courses you might interested")}</h3>
                      </NoItems>
                    )}
                    <SideWidget
                      title={isEmpty ? undefined : t("All Courses")}
                      description={
                        isEmpty ? (
                          <>
                            {t("Provide by")} <span>{merchant.name}</span>
                          </>
                        ) : undefined
                      }
                      sortable={!isEmpty}
                      sort={[
                        {
                          label: t("Newest"),
                          value: t("createdDate"),
                        },
                        {
                          label: t("Highest Price"),
                          value: "desc|price",
                        },
                        {
                          label: t("Lowest Price"),
                          value: "asc|price",
                        },
                        {
                          label: t("Top Student Reviews"),
                          value: "averageRating",
                        },
                      ]}
                      sortValue={state.sortBy}
                      onSortChange={(v) => setState({ sortBy: v })}>
                      <div
                        id={"all-courses"}
                        style={{
                          opacity: 0,
                          position: "relative",
                          top: -165,
                          pointerEvents: "none",
                        }}
                      />
                      <esc.FilterValuesContainer hidden={isEmpty}>
                        {categories.map((category, i) => {
                          if (
                            state.categoryIds
                              .concat(state.filterByCourseTypes)
                              .includes(category.id)
                          ) {
                            return <span key={i}>{category.name}</span>;
                          }
                          return null;
                        })}
                        {authors.map((author, i) => {
                          if (state.authorIds.includes(author.id)) {
                            return <span key={i}>{author.name}</span>;
                          }
                          return null;
                        })}
                        {popularFilterList.map((v, i) => {
                          if (state.filterByPopular.includes(v.id)) {
                            return <span key={i}>{v.name}</span>;
                          }
                          return null;
                        })}
                        {merchantFilterList.map((v, i) => {
                          if (state.filterByProviders.includes(v.id)) {
                            return <span key={i}>{v.name}</span>;
                          }
                          return null;
                        })}
                      </esc.FilterValuesContainer>
                      <div
                        className="section section-padding-bottom"
                        hidden={!fetchProductsResult.fetching}>
                        <div className="container">
                          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                            <div className={"col max-mb-30"}>
                              <div className={"d-skeleton-image"}>
                                <Skeleton active={true} paragraph={{ rows: 0 }} />
                              </div>
                              <Skeleton active={true} />
                            </div>
                            <div className={"col max-mb-30"}>
                              <div className={"d-skeleton-image"}>
                                <Skeleton active={true} paragraph={{ rows: 0 }} />
                              </div>
                              <Skeleton active={true} />
                            </div>
                            <div className={"col max-mb-30"}>
                              <div className={"d-skeleton-image"}>
                                <Skeleton active={true} paragraph={{ rows: 0 }} />
                              </div>
                              <Skeleton active={true} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="section section-padding-bottom"
                        hidden={fetchProductsResult.fetching}>
                        <div className="container">
                          {/*{isEmpty && (*/}
                          {/*  <div style={{ textAlign: "center", height: "calc(100vh / 2)" }}>*/}
                          {/*    <MCC>*/}
                          {/*      <Empty*/}
                          {/*        image={Empty.PRESENTED_IMAGE_SIMPLE}*/}
                          {/*        description={"No products."}*/}
                          {/*      />*/}
                          {/*    </MCC>*/}
                          {/*  </div>*/}
                          {/*)}*/}
                          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                            {/*Courses*/}
                            {(isEmpty ? state.newestProducts : products).map((item, i) => (
                              <RenderItem
                                key={i}
                                item={item}
                                index={i}
                                type={"product"}
                                sortBy={state.sortBy}
                              />
                            ))}
                            {/*  */}
                          </div>
                          <div className="row max-mt-50" hidden={isEmpty}>
                            <div className="col">
                              <RenderPagination
                                currentPage={state.currentPage}
                                totalItem={state.totalItem}
                                itemPerPage={state.itemPerPage}
                                handlePageChange={handlePageChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SideWidget>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MaxcoachProductsPage;

export function SideWidget(props) {
  const {t} = useTranslation()
  const [expanded, setExpanded] = useState(props.defaultExpand);

  const headerClasses = props.sortable
    ? "row row-cols-lg-2 row-cols-md-2 row-cols-1"
    : "row row-cols-1";

  return (
    <div style={{ position: "relative", clear: "both" }}>
      <div className="sidebar-widget-wrapper pr-0" style={{ position: "relative" }} {...props}>
        <div className="sidebar-widget">
          <div className={headerClasses}>
            <div className={"col"}>
              {props.title && (
                <h3
                  onClick={() => {
                    if (props.hideExpandButton) return;
                    setExpanded(!expanded);
                  }}
                  className="sidebar-widget-title"
                  style={{
                    position: "relative",
                    marginBottom: props.canExpand ? 15 : undefined,
                    cursor: props.canExpand ? "pointer" : undefined,
                    marginLeft: props.canExpand ? -15 : 0,
                  }}>
                  <span style={{ fontSize: props.canExpand ? 16 : undefined }}>{props.title}</span>
                  {props.canExpand && !props.hideExpandButton && (
                    <esc.ExpandButton>
                      <MCC>
                        <span className={"dx-btn"}>
                          <i className={expanded ? "fas fa-chevron-up" : "fas fa-chevron-down"} />
                        </span>
                      </MCC>
                    </esc.ExpandButton>
                  )}
                </h3>
              )}
              {props.description && (
                <SideWidgetDescription>{props.description}</SideWidgetDescription>
              )}
            </div>
            {props.sortable && (
              <div className={"col"} style={{ textAlign: "right" }}>
                <SideSortSection>
                  <span className={"dx-label"}>{t("Sort by")}:</span>
                  <Select
                    bordered={false}
                    value={props.sortValue}
                    style={{ minWidth: 200 }}
                    onChange={props.onSortChange}>
                    {_.map(props.sort, (v, i) => {
                      return (
                        <Option key={i} value={v.value}>
                          {v.label}
                        </Option>
                      );
                    })}
                  </Select>
                </SideSortSection>
              </div>
            )}
          </div>
          <div className="sidebar-widget-content" hidden={props.canExpand && !expanded}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

SideWidget.propTypes = {
  title: PropTypes.string,
  canExpand: PropTypes.bool,
  defaultExpand: PropTypes.bool,
  sortable: PropTypes.bool,
  sort: PropTypes.array,
  sortValue: PropTypes.any,
  onSortChange: PropTypes.func,
};

SideWidget.defaultProps = {
  defaultExpand: true,
};

export function FilterItems(props) {
  const {t} = useTranslation()
  const [treeItems, setTreeItems] = useState([]);
  const [searchText, setSearchText] = useState("");

  useDebounce(
    () => {
      const items = (props.items || [])
        .map((v) => {
          return {
            key: v.id || v.value,
            title: v.label || v.name,
            ...v,
          };
        })
        .filter((v) => {
          if (searchText.length === 0) return true;

          return v.title.toLowerCase().includes(searchText.toLowerCase());
        });
      const defaultParentId = "00000000-0000-0000-0000-000000000000";

      _.forEach(items, (item, i) => {
        if (item.parentId === defaultParentId) return;

        const index = _.findIndex(items, (v) => v.id === item.parentId);

        if (index === -1) {
          items[i].parentId = defaultParentId;
        }
      });

      setTreeItems(flat2Tree(items));
    },
    275,
    [props.items, searchText]
  );

  return (
    <React.Fragment>
      {props.search && (
        <esc.SearchItemContainer>
          <CustomInput>
            <Input
              placeholder={props.placeholder || t("Find a topic")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onFocus={(e) => e.target.select()}
              onClick={(e) => e.target.select()}
              className={!!searchText ? "has-value" : "no-value"}
            />
          </CustomInput>
        </esc.SearchItemContainer>
      )}
      <esc.LoadingItemContainer hidden={!props.loading}>
        <Skeleton active={true} paragraph={{ rows: 1 }} />
      </esc.LoadingItemContainer>
      <esc.FilterItemContainer hidden={props.loading}>
        <Tree
          key={JSON.stringify(treeItems)}
          checkable
          defaultExpandAll={true}
          autoExpandParent={true}
          selectable={false}
          checkedKeys={props.value}
          onCheck={(e) => {
            if (props.checkStrictly) {
              const { checked: checkedKeys } = e;
              props.onChange && props.onChange(_.cloneDeep(checkedKeys));
            } else {
              props.onChange && props.onChange(_.cloneDeep(e));
            }
          }}
          treeData={treeItems}
          checkStrictly={props.checkStrictly}
          filterTreeNode={(node) => {
            if (searchText.length === 0) return false;

            return node.title.toLowerCase().includes(searchText.toLowerCase());
          }}
        />
      </esc.FilterItemContainer>
    </React.Fragment>
  );
}

FilterItems.defaultProps = {
  checkStrictly: true,
};

export function RenderItem(props) {
  const {t} = useTranslation()
  const history = useHistory();
  const merchant = useSelector((state) => state.Merchant.merchant);
  const [brandRef, brandInfo] = useMeasure();
  const [brandWidth, setBrandWidth] = useState(0);
  const [hasDiscounts, priceInfo, discountLabel] = useDiscount(props.item);

  useDebounce(
    () => {
      setBrandWidth(brandInfo.width);
    },
    275,
    [brandInfo.width]
  );

  const { item } = props;
  const itemType = props.type;
  const isProduct = itemType === "product";
  const isCourse = itemType === "my-course";
  let courseUrl = `/product/${item.id}/${Common.rewriteUrl(item.name)}`;
  const students = parseInt(item.quantityStudent);
  const showStar = item.quantityRating > 0;
  const rateValue = parseFloat(item.averageRating).toFixed(1);
  const rateCount = item.quantityRating;

  // console.log("item", item);

  if (isCourse) {
    courseUrl = `/user-course-details/${item.id}`;
  }

  if (hasDiscounts) {
    console.log(priceInfo);
  }

  return (
    <esc.ProductItem
      className="col max-mb-30 animate__animated animate__fadeInUp"
      onClick={() => {
        if (props.newTab) {
          const win = window.open(courseUrl);

          win.focus();
        } else {
          history.push(courseUrl);
        }
      }}>
      <div className="course-2">
        <div className="thumbnail">
          <Link to={"/"} className="image" onClick={(e) => e.preventDefault()}>
            <span>
              <MCC className={"img-object-fit-container"}>
                <img
                  className={"img-object-fit"}
                  style={{ display: "inline-block" }}
                  src={Common.getImageThumbnail(
                    item.images,
                    Configuration.imageFormatCoursesMaxcoach
                  )}
                  alt="Course"
                />
                <span className={"dx-hours"}>
                  {formatTimeToLearn((item.periodCourse || item.period) * 60)}
                </span>
                <span
                  className={"dx-brand"}
                  hidden={!item.merchantLogo}
                  style={{
                    width: brandWidth === 0 ? undefined : brandWidth + 12,
                  }}>
                  <img
                    ref={brandRef}
                    src={Configuration.imageUrl + item.merchantLogo}
                    alt={"Brand"}
                  />
                </span>
              </MCC>
            </span>
          </Link>
        </div>
        <div className="info" style={{ height: "calc(100% - 250px)" }}>
          {isProduct && (
            <esc.PreviewCourseInfo>
              {!showStar && <p>{t("No review yet")}</p>}
              {showStar && (
                <p>
                  <i className="fas fa-star" /> <span>{rateValue}</span> ({rateCount})
                </p>
              )}
              <p>
                <span>{Number(students).toLocaleString("vi-VN")}</span> {t("students")}
              </p>
            </esc.PreviewCourseInfo>
          )}
          <h3 className="title">
            <Tooltip title={item.name.length > 50 ? item.name : undefined}>
              <Link to={"/"} onClick={(e) => e.preventDefault()}>
                {Common.formatName(item.name, 50)}
              </Link>
            </Tooltip>
          </h3>
          <p
            className={"dx-author"}
            style={{
              marginBottom: isCourse ? 50 : undefined,
            }}>
            {getAuthorNames(item, merchant)}
          </p>
        </div>
        {isProduct && (
          <div className={"dx-enroll"}>
            <button className={"btn btn-primary"}>{t("Enroll Now")}</button>
          </div>
        )}
        {isProduct && (
          <h3 className="dx-price whitespace-nowrap">
            {!hasDiscounts && <>{Number(item.price).toLocaleString("vi-VN")}₫</>}
            {hasDiscounts && (
              <>
                <span className="inline-block">
                  {Number(priceInfo.price).toLocaleString("vi-VN")}₫
                </span>
                <span className="inline-block ml-2 text-sm line-through opacity-50">
                  {Number(priceInfo.oldPrice).toLocaleString("vi-VN")}₫
                </span>
              </>
            )}
          </h3>
        )}
        {/*{props.index === 0 && <div className={"dx-rank dx-rank-blue"}>Popular</div>}*/}
        {isProduct && ((props.index < 3 && props.sortBy === "createdDate") || !!discountLabel) && (
          <div
            className={"dx-rank" + (discountLabel ? " dx-rank-blue" : " dx-rank-pink")}
            title={!!discountLabel ? discountLabel : "New"}>
            {!!discountLabel ? discountLabel : "New"}
          </div>
        )}
        {isCourse &&
          (() => {
            const timeLeft = (item.period - (item.period * item.percent) / 100) * 60;
            const formattedPeriod = formatTimeToLearn(timeLeft)
              .replace(/m/g, " minutes")
              .replace(/h/g, " hours");

            return (
              <div className={"dx-price"}>
                <CourseProgressBar>
                  <label>
                    {item.percent > 0 && (
                      <>
                        {item.percent >= 100 && <span>Completed</span>}
                        {item.percent < 100 && (
                          <>
                            <span>{formattedPeriod}</span> left
                          </>
                        )}
                      </>
                    )}
                    {!(item.percent > 0) && <>Course not started</>}
                  </label>
                  <CourseProgressBarValue style={{ width: `${item.percent}%` }} />
                </CourseProgressBar>
              </div>
            );
          })()}
      </div>
    </esc.ProductItem>
  );
}

export function CardCarousel(props) {
  console.log(props,"911")
  const {t} = useTranslation()
  const carouselRef = useRef(null);
  const [state, setState] = useSetState({
    currentSlide: 0,
  });

  const _nextSlide = () => {
    carouselRef && carouselRef.current.next();
  };
  const _prevSlide = () => {
    carouselRef && carouselRef.current.prev();
  };

  useEffect(() => {
    // carouselRef.current.goTo(0);
  }, [props.item]);
  const renderItems = ()=>{

    const items = props.items || [];
    const itemsLength = items.length;
    return {items,itemsLength}
  }

  return (
    <esc.SlideSlipRoot>
      <SideWidget title={t(props.title)}>
        <esc.SideSlipContainer>
          <Carousel
            autoplay={true}
            ref={carouselRef}
            afterChange={(currentSlide) => setState({ currentSlide })}>
            {renderItems().items.map((item, i) => (
              <esc.SideSlipItem key={i}>{props.renderItem(item, i)}</esc.SideSlipItem>
            ))}
          </Carousel>
        </esc.SideSlipContainer>
        {state.currentSlide < renderItems().itemsLength - 1 && (
          <button className={"dx-next"} onClick={_nextSlide}>
            <MCC>
              <i className="fas fa-chevron-right" />
            </MCC>
          </button>
        )}
        {state.currentSlide > 0 && (
          <button className={"dx-prev"} onClick={_prevSlide}>
            <MCC>
              <i className="fas fa-chevron-left" />
            </MCC>
          </button>
        )}
      </SideWidget>
    </esc.SlideSlipRoot>
  );
}

export function RenderPagination(props) {
  return (
    <esc.PaginationSection>
      <ul className="pagination center" style={{ whiteSpace: "nowrap" }}>
        <Pagination
          total={props.totalItem}
          pageSize={props.itemPerPage}
          showSizeChanger={false}
          responsive={true}
          current={props.currentPage + 1}
          onChange={(pageIndex) => {
            props.handlePageChange && props.handlePageChange(pageIndex - 1);
          }}
          itemRender={(page, type) => {
            if (!["page", "prev", "next"].includes(type)) return null;

            const renderText = (() => {
              if (type === "prev") {
                return <i className="fas fa-chevron-left" />;
              } else if (type === "next") {
                return <i className="fas fa-chevron-right" />;
              }

              return page;
            })();

            return (
              <li>
                <Link
                  to={"/"}
                  onClick={(e) => e.preventDefault()}
                  className={(() => {
                    const isCurrentPage = page - 1 === props.currentPage;

                    if (type === "page" && isCurrentPage) {
                      return "active";
                    } else if (["prev", "next"].includes(type)) {
                      const maxPage = Math.ceil(props.totalItem / props.itemPerPage);
                      const isDisabled = (() => {
                        if (type === "next") {
                          return props.currentPage === maxPage - 1;
                        }

                        return props.currentPage === 0;
                      })();

                      return ["navigation-button ", isDisabled ? "disabled" : ""].join(" ");
                    }

                    return undefined;
                  })()}>
                  {props.currentPage === page - 1 && (
                    <div>
                      {props.loadingFollow && type === "page" ? (
                        <Spin indicator={SpinIcon} />
                      ) : (
                        <span>{renderText}</span>
                      )}
                    </div>
                  )}
                  {props.currentPage !== page - 1 && <span>{renderText}</span>}
                </Link>
              </li>
            );
          }}
        />
      </ul>
    </esc.PaginationSection>
  );
}

RenderPagination.propTypes = {
  handlePageChange: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalItem: PropTypes.number.isRequired,
  itemPerPage: PropTypes.number.isRequired,
};

//////

function flat2Tree(array, parent, tree) {
  const defaultParentId = "00000000-0000-0000-0000-000000000000";

  tree = typeof tree !== "undefined" ? tree : [];
  parent = typeof parent !== "undefined" ? parent : { id: defaultParentId };

  const children = _.filter(array, function (child) {
    return child.parentId === parent.id;
  });

  if (!_.isEmpty(children)) {
    if (parent.id === defaultParentId) {
      tree = children;
    } else {
      parent["children"] = children;
    }

    _.each(children, function (child) {
      flat2Tree(array, child);
    });
  }

  return tree;
}

export function formatTimeToLearn(seconds) {
  if(seconds > 18000){
    console.log(seconds);
  }
  seconds = parseFloat(seconds).toFixed(0);

  if (seconds < 60 && seconds > 0) return `${seconds}s`;

  const duration = moment.duration(seconds, "seconds");
  const stringBuilder = [];
  var hours = 0;
  // if (duration.years() > 0) {
  //   stringBuilder.push(duration.years() + "năm");
  // }
  // if (duration.months() > 0) {
  //   stringBuilder.push(duration.months() + "tháng");    
  // }

  if (duration.days() > 0) {
    //stringBuilder.push(duration.days() + "ngày");
    hours = hours + duration.days()*24;
  }

  if (duration.hours() > 0) {
    stringBuilder.push((hours + duration.hours()) + "h");
  }

  stringBuilder.push(duration.minutes() + "m");

  return stringBuilder.join(" ");
}

export function getAuthorNames(item, merchant) {
  const authors = item.authors || [];
  const authorNames = authors
    .map((v) => [v.lastName, v.firstName].filter((v) => v && v.length > 0).join(" "))
    .filter((v) => v && v.length);

  if (authorNames.length === 0) {
    if (merchant) {
      authorNames.push(merchant.name);
    } else {
      authorNames.push("Anonymous");
    }
  }

  return ["By:", authorNames.join(", ")].join(" ");
}

export function getAuthorAvatar(item, merchant, resizeMode = "?mode=crop&width=33&height=33") {
  return Common.getImageThumbnail(merchant.images, resizeMode);
}
